<div class="content-loader">
    <ngx-skeleton-loader appearance="circle" [theme]="circleStyle"></ngx-skeleton-loader>
    <div class="w-10">
        <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <div class="w-33">
        <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <div class="w-10">
        <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <div class="w-10">
        <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <ngx-skeleton-loader count="2" appearance="circle" [theme]="circleStyle"></ngx-skeleton-loader>
</div>