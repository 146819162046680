import { ReclamosPageComponent } from './pages/reclamos-page/reclamos-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReclamoDetallePageComponent } from './pages/reclamo-detalle-page/reclamo-detalle-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'consultas',
    pathMatch: 'full',
  },
  {
    path: 'consultas',
    component: ReclamosPageComponent,
    data: { subTitle: 'Consultas' },
  },
  {
    path: 'consulta/:id',
    component: ReclamoDetallePageComponent,
    data: { subTitle: 'Detalle de Reclamo' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  })
export class ReclamosRoutingModule { }
