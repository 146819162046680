import { Component, Input } from '@angular/core';
import { TicketStatus } from '../../interfaces/reclamos.interfaces';

@Component({
  selector: 'agd-reclamo-estado-consulta',
  templateUrl: './reclamo-estado-consulta.component.html',
  styleUrls: ['./reclamo-estado-consulta.component.scss']
  })
export class ReclamoEstadoConsultaComponent {
  @Input() ticketStatus: TicketStatus;
}
