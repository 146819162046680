import {
  Component, ContentChildren,
  EventEmitter, Input, OnDestroy,
  Output, QueryList
} from '@angular/core';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import { SubSink } from 'subsink';
import { CardComponent } from '../card/card.component';

export interface AgdCardListUpdateEvent {
  complete: (endOfList?: boolean) => void;
}

@Component({
  selector: 'agd-cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.scss'],
  animations: [AppAnimations.fadeIn],
})
export class CardsListComponent implements OnDestroy {
  @ContentChildren(CardComponent) cardList: QueryList<CardComponent>;

  @Input() endOfList = false;

  @Input() loading = false;

  @Output() update = new EventEmitter<AgdCardListUpdateEvent>();

  @Output() appendUpdate = new EventEmitter<AgdCardListUpdateEvent>();

  appendLoading = false;

  noMoreResults = false;

  skeletonElements = new Array(8);

  private subs = new SubSink();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  updateList(event?) {
    this.appendLoading = false;
    this.noMoreResults = false;

    this.update.emit({
      complete: () => {
        event?.target.complete();
      }
    });
  }
}
