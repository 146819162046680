import { Notification } from '../../../../../../_shared/modules/agd-components/notifications/models/notification.model';
import { NotificationsService } from 'src/app/_shared/modules/agd-components/notifications/services/notifications/notifications.service';
import { RetentionsService } from 'src/app/_core/services/retentions/retentions.service';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { CuitChangedService } from 'src/app/_core/services/common/cuit-changed/cuit-changed.service';
import { SubSink } from 'subsink';
import {
  FormBuilder, FormGroup, Validators, FormControl, ValidationErrors
} from '@angular/forms';
import {
  Component, OnInit, OnDestroy, ViewChild
} from '@angular/core';
import { conditionalRequired, fileFormatValidator, fileSizeValidator } from 'src/app/_shared/helpers/formsCustomValidators';
import { ConfigurationService } from 'src/app/_core/services/configuration/configuration.service';
import { ModulesPermissions } from 'src/app/_shared/helpers/applicationConstants';
import { ReclamosService, TicketFormData } from '../../services/reclamos.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { Motivo } from '../../models/motivo-consulta.model';
import { SubMotivo } from '../../models/submotivo-consulta.model';

@Component({
  selector: 'agd-nueva-consulta',
  templateUrl: './nueva-consulta.component.html',
  styleUrls: ['./nueva-consulta.component.scss'],
  animations: [AppAnimations.fadeIn]
  })
export class NuevaConsultaComponent implements OnInit, OnDestroy {
  static modalId = 'modal-nueva-consulta';

  @ViewChild('stepper') stepper: MatStepper;

  form: FormGroup;

  loading = false;

  selectedCUIT: string;

  businessName: string;

  motivos: Motivo[];

  subMotivos: SubMotivo[];

  documentTypes: Record<string, string>;

  invalidFormatError = false;

  invalidSizeError = false;

  subMotivosLoading = false;

  documentTypesLoading = false;

  snackbarConfig: MatSnackBarConfig<any> = {
    duration: 10000,
    verticalPosition: 'top'
  };

  validFileFormats = `
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
    application/pdf,
    image/jpeg,
    image/png,
    application/msword,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
    application/vnd.ms-powerpoint,
    application/vnd.openxmlformats-officedocument.presentationml.presentation,
    image/bmp,
    image/gif
  `;

  private subs = new SubSink();

  private TUTORIAL: Notification;

  constructor(
    private cuitChangedService: CuitChangedService,
    private userDataService: UserDataService,
    private fb: FormBuilder,
    private config: ConfigurationService,
    private reclamosService: ReclamosService,
    private snackBar: MatSnackBar,
    private router: Router,
    private retentionsService: RetentionsService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      step1: this.fb.group({
        motivo: this.fb.control(undefined, [Validators.required, this.invalidModuleOption(ModulesPermissions.COMPROBANTES)]),
        subMotivo: this.fb.control({ value: undefined, disabled: true }, [
          Validators.required]),
      }),
      step2: this.fb.group({
        tipoDocumento: this.fb.control(undefined),
        nroDocumento: this.fb.control({ value: undefined, disabled: true }, [
          conditionalRequired('tipoDocumento'), Validators.maxLength(255)
        ]),
        descripcion: this.fb.control(undefined, [Validators.required, Validators.maxLength(250)]),
        archivo: this.fb.control(undefined),
      })
    });

    // Get tutorial data
    this.subs.sink = this.retentionsService.getClaimTutorial().subscribe((response: any) => {
      this.TUTORIAL = new Notification(response.reclamosTutorialData);
    });

    // react to cuit changes
    this.subs.sink = this.cuitChangedService.activeCuitChanged.subscribe((newCuit) => this.cuitChanged(newCuit));

    this.subs.sink = this.cuitChangedService.permissionsChanged.subscribe((permissions: any) => {
      if (permissions) {
        // Initialize cuit data
        const cuit: any = this.userDataService.getActiveCUIT();
        const cuitData = this.userDataService.userCuitsData.find(data => data.cuit === cuit);
        this.selectedCUIT = cuitData.cuit;
        this.businessName = cuitData.razonSocial;
        // Initialize module list
        this.initModulesList();
      }
    });

    // React to module selection change
    const motivoCtrl = this.form.get('step1.motivo');

    const subMotivoCtrl = this.form.get('step1.subMotivo');

    const otherControllers = [
      this.form.get('step2.descripcion'),
      this.form.get('step2.tipoDocumento'),
      this.form.get('step2.nroDocumento'),
      this.form.get('step2.archivo')
    ];

    this.subs.sink = motivoCtrl.valueChanges.subscribe(async value => {
      // reset document types list and value
      this.documentTypes = null;
      this.form.get('step2.tipoDocumento').reset(undefined);
      this.form.get('step2.nroDocumento').reset({ value: undefined, disabled: true });

      if (motivoCtrl.hasError('invalidOption')) {
        if (!this.TUTORIAL) {
          this.loading = true;

          const response: any = await this.retentionsService.getClaimTutorial().toPromise();
          this.TUTORIAL = new Notification(response.reclamosTutorialData);

          this.loading = false;
        }

        this.notificationsService.showModalNotification(this.TUTORIAL);

        subMotivoCtrl.reset({ value: undefined, disabled: true });
        // disable the rest of the form
        otherControllers.forEach(ctrl => {
          ctrl.reset({ value: undefined, disabled: true });
        });
      } else {
        subMotivoCtrl.reset(undefined);

        // Enable controls.
        otherControllers.forEach(ctrl => {
          ctrl.reset({ value: undefined, disabled: false });
        });

        // if not selected "Otro" option
        if (value !== '0') {
          this.subMotivosLoading = true;
          this.subMotivos = [];
          // Get 'submotivos' list
          this.subs.sink = this.reclamosService.getSubmotivos(value).subscribe((submotivos) => {
            let dashboardSelected = false;
            // special case
            if (value === ModulesPermissions.RESUMEN_GLOBAL) {
              this.subMotivos = submotivos;
              dashboardSelected = true;
              this.documentTypes = null;
            } else {
              // filter 'SubMotivos' when 'Motivo' is selected and unrelated to grid
              submotivos.forEach((subMotivoNoGrid) => {
                if (subMotivoNoGrid.asociadoModulo === 0) {
                  this.subMotivos.push(subMotivoNoGrid);
                }
              });

              // filer by cuit permission
              const cuitPermissions = this.userDataService.getActiveCUITPermissions();
              const moduleSubgrids = cuitPermissions.find(permission => permission.moduloId === value);

              if (moduleSubgrids) {
                moduleSubgrids.grillasId.forEach(grillaId => {
                  const subModule = submotivos.find(subM => grillaId === subM.subMotivoId);

                  if (subModule) {
                    this.subMotivos.push(subModule);
                  }
                });
              }
            }

            if (this.subMotivos.length) {
              // if grids list only have 1 option, select that option
              if (this.subMotivos.length === 1) {
                subMotivoCtrl.setValue(this.subMotivos[0].id, { emitEvent: !dashboardSelected });
              }
              subMotivoCtrl.enable({ emitEvent: false });
            }

            this.subMotivosLoading = false;
          }, () => {
            this.subMotivosLoading = false;
          });
        }
      }
    });

    // React to sub-module selection change
    this.subs.sink = subMotivoCtrl.valueChanges.subscribe((value) => {
      if (!value) return;

      const subMotivo = this.subMotivos.find(subModule => subModule.id.toString() === value.toString());

      // reset document type value
      this.form.get('step2.tipoDocumento').reset(undefined);
      this.form.get('step2.nroDocumento').reset({ value: undefined, disabled: true });

      if (subMotivo) {
        this.documentTypesLoading = true;
        this.subs.sink = this.reclamosService.getDocumentTypes(motivoCtrl.value, subMotivo.subMotivoId)
          .subscribe((documentTypes) => {
            if (typeof documentTypes === 'object' && Object.keys(documentTypes).length) {
              this.documentTypes = documentTypes;
            } else {
              this.documentTypes = null;
            }

            this.documentTypesLoading = false;
          }, () => {
            this.documentTypes = null;
            this.documentTypesLoading = false;
          });
      }
    });

    // React to document type selection change
    const tipoDocumentoCtrl = this.form.get('step2.tipoDocumento');

    this.subs.sink = tipoDocumentoCtrl.valueChanges.subscribe(val => {
      if (val && val !== '-1') {
        this.form.get('step2.nroDocumento').enable();
      } else {
        this.form.get('step2.nroDocumento').reset({ value: undefined, disabled: true });
      }
    });

    // React to file selection change
    const fileCtrl = this.form.get('step2.archivo');

    this.subs.sink = fileCtrl.valueChanges.subscribe(val => {
      if (!val) return;

      const fileFormatFn = fileFormatValidator(this.validFileFormats);
      const fileSizeFn = fileSizeValidator(8);

      this.invalidFormatError = false;
      this.invalidSizeError = false;

      if (fileFormatFn(fileCtrl as FormControl)) {
        this.invalidFormatError = true;
        fileCtrl.setValue(undefined);
        return;
      }

      if (fileSizeFn(fileCtrl as FormControl)) {
        this.invalidSizeError = true;
        fileCtrl.setValue(undefined);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  sendTicket() {
    if (this.loading) return;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { step1, step2 } = this.form.value;

    const value = { ...step1, ...step2 };

    const data: TicketFormData = {
      cuit: this.selectedCUIT,
      descripcion: value.descripcion?.trim(),
      ...(value.archivo && { adjunto: value.archivo }),
      ...(value.tipoDocumento && value.tipoDocumento !== -1 && { tipoDocumento: value.tipoDocumento }),
      ...(value.nroDocumento && { nroDocumento: value.nroDocumento }),
      ...(value.subMotivo && { subMotivoId: value.subMotivo })
    };

    this.loading = true;

    this.subs.sink = this.reclamosService.createTicket(data).subscribe((res: any) => {
      this.snackBar.open(`¡La consulta se creó correctamente con #${res.ticket}`, 'Cerrar', this.snackbarConfig);
      this.closeModal({
        update: true,
      });
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  previous() {
    this.stepper.previous();
  }

  next() {
    if (this.stepper.selectedIndex < this.stepper.steps.length - 1) {
      this.stepper.next();
    }
  }

  closeModal(result?: Record<any, any>) {
    const modalref = this.dialog.getDialogById(NuevaConsultaComponent.modalId);

    modalref?.close(result);
  }

  getCtrl(controlSelector: string): FormControl {
    return this.form.get(controlSelector) as FormControl;
  }

  getValue(controlSelector: string) {
    return this.form.get(controlSelector).value;
  }

  private initModulesList() {
    const motivoCtrl = this.form.get('step1.motivo');
    motivoCtrl.reset(undefined);
    // Get 'motivos' data
    this.subs.sink = this.reclamosService.getMotivos().subscribe((motivos) => {
      this.motivos = [];
      // filter by cuit permission and 'motivos' unrelated to grids
      const cuitPermissions = this.userDataService.getActiveCUITPermissions();
      this.motivos = motivos.filter(m => m.asociadoModulo === 0
         || (m.asociadoModulo === 1 && cuitPermissions.find(cuitP => cuitP.moduloId === m.id)));
    });
  }

  private cuitChanged(cuit: string) {
    this.userDataService.setActiveCUIT(cuit);
  }

  /**
   * Custom validator for invalid module option
   */
  private invalidModuleOption(invalidValue: string) {
    return (control: FormControl): ValidationErrors => {
      if (!this.motivos?.length || !control.value || !invalidValue) {
        return undefined;
      }

      const invalidOption = this.motivos.find(m => m.id === invalidValue);

      const invalidOptionSelected = control.value?.toString() === invalidOption?.id?.toString();

      return invalidOptionSelected ? { invalidOption: true } : undefined;
    };
  }
}
