import {
  Component, Input
} from '@angular/core';
import { AppAnimations } from 'src/app/_shared/animations/animations';

@Component({
  selector: 'agd-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [AppAnimations.fadeIn],
})
export class CardComponent {
  @Input() loading = false;
}
