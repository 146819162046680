import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'secure'
  })
export class SecurePipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  transform(url: string): Observable<SafeUrl> {
    // sanitize "url" value for security purpose
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    return this.http
      .get(sanitizedUrl, { responseType: 'blob' })
      .pipe(map(
        // sanitize "val" value for security purpose
        val => this.sanitizer.sanitize(SecurityContext.URL, URL.createObjectURL(val))
      ));
  }
}
