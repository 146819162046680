import { Component } from '@angular/core';
import { AppAnimations } from 'src/app/_shared/animations/animations';

@Component({
  selector: 'agd-cards-list-header',
  templateUrl: './cards-list-header.component.html',
  styleUrls: ['./cards-list-header.component.scss'],
  animations: [AppAnimations.fadeIn],
})
export class CardsListHeaderComponent {}
