import { TicketData } from '../../interfaces/reclamos.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';
import { SubSink } from 'subsink';
import { CuitChangedService } from '../../../../../../_core/services/common/cuit-changed/cuit-changed.service';
import { takeUntil } from 'rxjs/operators';
import {
  Component, OnInit, OnDestroy, ViewChild, AfterViewInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { TableStatusEnum } from 'src/app/_shared/components/table-status/table-status.enum';
import { completeSubscription } from 'src/app/_shared/helpers/listRole';
import { USER_ROLES } from 'src/app/_shared/helpers/applicationConstants';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import {
  FiltersConfig
} from 'src/app/_shared/modules/agd-components/filters/models/filters.types';
import { MatDialog } from '@angular/material/dialog';
import { NuevaConsultaComponent } from '../../components/nueva-consulta/nueva-consulta.component';
import {
  FiltersToggleComponent
} from 'src/app/_shared/modules/agd-components/filters/components/filters-toggle/filters-toggle.component';
import { ReclamosService } from '../../services/reclamos.service';
import { FiltersService } from 'src/app/_shared/modules/agd-components/filters/services/filters.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-reclamos-page',
  templateUrl: './reclamos-page.component.html',
  styleUrls: ['./reclamos-page.component.scss'],
  animations: [AppAnimations.fadeIn]
  })
export class ReclamosPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(FiltersToggleComponent) filtersToolbar: FiltersToggleComponent;

  dataSource: any;

  gridStatus: TableStatusEnum = TableStatusEnum.LOADING;

  statusEnum = TableStatusEnum;

  dataSourceTotal = 0;

  hasHelpDeskRole = false;

  /**
   * Used in order to cancel request when component is destroyed.
   */
  ngUnsubscribe = new Subject();

  tableattr: any = {
    filters: null,
    from: '',
    to: '',
    order: '',
    orderBy: '',
    search: '',
    count: 8,
    page: 1,
    status: '',
  };

  noFilters: boolean;

  emptyListTitle: string;

  filters: FiltersConfig;

  mobileScreen: boolean;

  private subs = new SubSink();

  private firstLoad = true;

  constructor(
    private reclamosService: ReclamosService,
    // used in order to emit row data.
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cuitChangedService: CuitChangedService,
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private filtersService: FiltersService,
    private bpObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    // Check if user has desk help role
    this.hasHelpDeskRole = this.userDataService.hasPermission(USER_ROLES.MESA_DE_AYUDA);
    this.emptyListTitle = this.hasHelpDeskRole ? 'Todo en orden por aquí' : 'No tienes consultas';
    const largeScreens = [Breakpoints.Large, Breakpoints.XLarge];

    this.subs.sink = this.bpObserver.observe(largeScreens).subscribe((breakpoint) => {
      this.mobileScreen = !breakpoint.matches;
    });

    this.loadFiltersConfig();
  }

  ngAfterViewInit(): void {
    // set filters values from toolbar component
    this.tableattr.filters = this.filtersToolbar.getCurrentValue();

    this.subs.sink = this.cuitChangedService.activeCuitChanged.subscribe((cuit) => this.cuitChanged(cuit));
    this.subs.sink = this.cuitChangedService.permissionsChanged.subscribe((permissions: any) => {
      if (permissions) {
        this.permissionsChanged();
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Emit an event in order to show row details.
   * @param row row data.
   */
  viewDetails(row: TicketData) {
    this.router.navigate([`mesa-de-ayuda/consulta/${row.nroTicket}`], {
      state: { useBackHistory: true }
    });
  }

  /**
   * Handle Paginator event
   * @param e event
   */
  handlePage(e: any) {
    this.tableattr.page = e.page;
    this.tableattr.count = e.count;
    this.getComplaints();
  }

  applyFilters(filtersValue: Record<string, string>) {
    // If it's not the first load of the 'consultas' apply filters and getComplaints method
    if (!this.firstLoad) {
      this.tableattr.filters = filtersValue;

      // Update filters in url params
      this.router.navigate([], { queryParams: this.tableattr.filters, replaceUrl: true });

      this.completeSubscription();
      this.updateGrid();
    }
  }

  openNewClaim() {
    const ref = this.dialog.open(NuevaConsultaComponent, {
      id: NuevaConsultaComponent.modalId, panelClass: 'no-min-width'
    });

    this.subs.sink = ref.afterClosed().subscribe(result => {
      if (result?.update) {
        this.applyFilters(null);
        this.filtersToolbar.reset();
      }
    });
  }

  /**
   * Sets active cuit and clears grid.
   * @param cuit cuit
   */
  private cuitChanged(cuit: string) {
    this.completeSubscription();
    this.userDataService.setActiveCUIT(cuit);
  }

  /**
   * Update grid based on applied filters.
   */
  private updateGrid() {
    this.ngUnsubscribe = new Subject();
    // reset paginator
    this.getComplaints();
  }

  /**
   * Get list of Tickets
   */
  private getComplaints() {
    this.gridStatus = TableStatusEnum.LOADING;
    const { fecha: dateRange, ...filters } = this.tableattr.filters || {};

    const [fechaActualizacionDesde, fechaActualizacionHasta] = this.filtersService.parseDateFromToQuery(dateRange);
    this.noFilters = this.tableattr.filters === null;

    this.reclamosService
      .getTicketsList({
        pagina: this.tableattr.page,
        cantidad: this.tableattr.count,
        fechaActualizacionDesde,
        fechaActualizacionHasta,
        ordenadoPor: this.tableattr.orderBy,
        orden: this.tableattr.order,
        filtros: filters
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.dataSource = res.tickets;
          this.dataSourceTotal = res.cantidadTotal;
          this.firstLoad = false;

          if (this.dataSourceTotal === 0) {
            this.gridStatus = TableStatusEnum.EMPTY;
          } else {
            this.gridStatus = TableStatusEnum.DONE;
          }
        },
        (err: any) => {
          console.error(err);
          this.gridStatus = TableStatusEnum.ERROR;
          this.firstLoad = false;
        }
      );
  }

  /**
   * Unsubscribes all pending requests.
   */
  private completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }

  /**
   * Sets permissions and refresh menu
   * @param permissions permissions
   */
  private permissionsChanged() {
    this.ngUnsubscribe = new Subject();
    // If this is the first time we called this function in this component's life, then be
    // careful not to reset the filters since it could happen they've been set on the constructor.
    if (!this.firstLoad) {
      this.tableattr = {
        filters: {},
        from: '',
        to: '',
        order: '',
        orderBy: '',
        search: '',
        count: 8,
        page: 1,
        status: '',
      };
    }

    this.getComplaints();
  }

  private loadFiltersConfig(): void {
    this.noFilters = true;

    this.filters = {
      nroTicket: {
        label: 'Consulta N°',
        type: 'number',
      },
      ...(this.hasHelpDeskRole && {
        cuit: {
          label: 'CUIT',
          type: 'number',
        },
        razonSocial: {
          label: 'Razón Social',
          type: 'text',
        },
        usuario: {
          label: 'Usuario',
          type: 'text',
        }
      }),
      estado: {
        label: 'Estado',
        type: 'radio',
        options: Promise.resolve([
          {
            label: 'Abierto',
            value: 'abierto'
          },
          {
            label: 'Respondido',
            value: 'respondido'
          },
          {
            label: 'Cerrado',
            value: 'cerrado'
          }
        ])
      },
      fecha: {
        type: 'date-range',
        label: 'Rango de fecha',
      },
      ...(this.hasHelpDeskRole && {
        respondidoPorMi: {
          label: 'Respondidas por mi',
          type: 'switch',
        }
      }),
    };

    // Load initial filter values from query params
    const { queryParams } = this.activatedRoute.snapshot;

    if (Object.keys(queryParams)) {
      this.noFilters = false;

      Object.keys(this.filters).forEach(key => {
        this.filters[key].initValue = queryParams[key];
      });
    }
  }
}
