import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsListComponent } from './components/cards-list/cards-list.component';
import { CardComponent } from './components/card/card.component';
import { CardEmptyListComponent } from './components/card-empty-list/card-empty-list.component';
import { CardColumnComponent } from './components/card-column/card-column.component';
import { CardsListHeaderComponent } from './components/cards-list-header/cards-list-header.component';
import { CardHeaderColumnComponent } from './components/card-header-column/card-header-column.component';
import { CardSkeletonComponent } from './components/card-skeleton/card-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
  declarations: [
    CardsListComponent,
    CardComponent,
    CardEmptyListComponent,
    CardColumnComponent,
    CardsListHeaderComponent,
    CardHeaderColumnComponent,
    CardSkeletonComponent,
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        'background-color': '#eff1ff',
        height: '14px'
      }
    }),
  ],
  exports: [
    CardsListComponent,
    CardComponent,
    CardEmptyListComponent,
    CardColumnComponent,
    CardsListHeaderComponent,
    CardHeaderColumnComponent,
  ]
})
export class CardsModule { }
