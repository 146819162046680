import { ReclamosRoutingModule } from './reclamos-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from 'src/app/_shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ReclamoFormularioComponent } from './components/reclamo-formulario/reclamo-formulario.component';
import { ReclamoConfirmacionComponent } from './components/reclamo-confirmacion/reclamo-confirmacion.component';
import { ReclamoAyudaComponent } from './components/reclamo-ayuda/reclamo-ayuda.component';
import { ReclamoDetalleComponent } from './components/reclamo-detalle/reclamo-detalle.component';
import { ReclamosPageComponent } from './pages/reclamos-page/reclamos-page.component';
import { NuevaConsultaComponent } from './components/nueva-consulta/nueva-consulta.component';
import { ReclamoDetallePageComponent } from './pages/reclamo-detalle-page/reclamo-detalle-page.component';
import { ReclamoFinalizarModalComponent } from './components/reclamo-finalizar-modal/reclamo-finalizar-modal.component';
import { FilePreviewModule } from 'src/app/_shared/components/file-preview/file-preview.module';
import { CardsModule } from 'src/app/_shared/components/cards/cards.module';
import { ReclamoEstadoConsultaComponent } from './components/reclamo-estado-consulta/reclamo-estado-consulta.component';
import { FiltersModule } from 'src/app/_shared/modules/agd-components/filters/filters.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
  ReclamoFormularioComponent,
  ReclamoConfirmacionComponent,
  ReclamoAyudaComponent,
  ReclamoDetalleComponent,
  ReclamosPageComponent,
  NuevaConsultaComponent,
  ReclamoDetallePageComponent,
  ReclamoFinalizarModalComponent,
  ReclamoEstadoConsultaComponent,
  ],
  imports: [
  ReclamosRoutingModule,
  CommonModule,
  MatDialogModule,
  FormsModule,
  ReactiveFormsModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  SharedModule,
  FilePreviewModule,
  CardsModule,
  FiltersModule,
  MatStepperModule,
  MatProgressBarModule,
  ],
  providers: [
  DatePipe
  ],
  })
export class ReclamosModule { }
