/* eslint-disable no-shadow */
export interface TicketAttachment {
  id: number;
  url: string;
  urlThumbnail: string;
}
export interface TicketMessage {
  usuario: string;
  fecha: string | Date;
  mensaje: string;
  visto: boolean;
  nombre?: string;
  apellido?: string;
  respuesta?: boolean;
  adjuntos?: TicketAttachment[];
}

export interface TicketUserData {
  username: string;
  nombre?: string;
  apellido?: string;
}

export enum TicketStatus {
  ABIERTO = 'ABIERTO',
  RESPONDIDO = 'RESPONDIDO',
  CERRADO = 'CERRADO'
}

export interface TicketData {
  nroTicket: number;
  cuit: string;
  razonSocial: string;
  motivo: string;
  subMotivo: string;
  mensajes: TicketMessage[];
  estado: TicketStatus;
  feedback: boolean;
  fechaInicio: string | Date;
  fechaFinalizacion?: string | Date;
  tipoDocumento?: string;
  nroDocumento?: string;
  acopios?: string[];
  rol?: string;
  usuario?: TicketUserData;
  nroMensajes?: number;
  adjunto?: boolean;
}
