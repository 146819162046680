import { Component, Input } from '@angular/core';

@Component({
  selector: 'agd-card-empty-list',
  templateUrl: './card-empty-list.component.html',
  styleUrls: ['./card-empty-list.component.scss'],
})
export class CardEmptyListComponent {
  @Input() title = 'Todo en orden por aquí';

  @Input() filtered = false;
}
