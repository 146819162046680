import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
  selector: 'agd-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  @Input() thumbnail: string;

  @Output() clicked = new EventEmitter();

  onClick() {
    this.clicked.emit();
  }
}
