<div class="title">
  <label>Nueva consulta</label>
  <mat-icon (click)="closeModal()">close</mat-icon>
</div>
<div class="edge-box">
  <mat-icon svgIcon="alert_icon"></mat-icon>
  <label>
    Completa los datos en cada campo obligatorio para crear una consulta.
  </label>
</div>
<mat-progress-bar [value]="(stepper.selectedIndex + 1)/2 * 100">
</mat-progress-bar>
<form [formGroup]="form">
  <mat-stepper #stepper>
    <mat-step formGroupName="step1">
      <div class="row margin-content">
        <div class="col-12">
          <div class="form-group data-group">
            <label class="label">CUIT</label>
            <span>{{ selectedCUIT | formatCUIT }}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group data-group">
            <label class="label">Razón Social</label>
            <span>{{ businessName }}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="label required" for="motivo">Motivo</label>
            <select class="form-control" formControlName="motivo" id="motivo">
              <option [value]="null" hidden disabled></option>
              <option *ngFor="let motivo of motivos" [value]="motivo.id">{{ motivo.titulo }}</option>
            </select>
            <small class="text-danger float-left"
              *ngIf="getCtrl('step1.motivo')?.touched && getCtrl('step1.motivo')?.hasError('required')">
              Seleccione una opción
            </small>
            <small class="text-danger float-left"
              *ngIf="getCtrl('step1.motivo')?.touched && getCtrl('step1.motivo')?.hasError('invalidOption')">
              Seleccione una opción diferente
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="label required" for="subMotivo">Sub motivo</label>
            <mat-progress-spinner *ngIf="subMotivosLoading" mode="indeterminate" diameter="25" class="edit-spinner">
            </mat-progress-spinner>
            <select class="form-control" formControlName="subMotivo" id="subMotivo">
              <option *ngFor="let subMotivo of subMotivos" [value]="subMotivo.id">{{ subMotivo.subMotivoTitulo }}</option>
            </select>
            <small class="text-danger float-left"
              *ngIf="getCtrl('step1.subMotivo')?.touched && getCtrl('step1.subMotivo')?.hasError('required')">
              Seleccione una opción
            </small>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step formGroupName="step2">
      <div class="row margin-content" *ngIf="!documentTypesLoading && (documentTypes | keyvalue)?.length" @fadeIn>
        <div class="col-12">
          <div class="form-group">
            <label class="label" for="tipoDocumento">Tipo de Documento</label>
            <select class="form-control" formControlName="tipoDocumento" id="tipoDocumento">
              <option [value]="-1">Sin tipo de documento</option>
              <option *ngFor="let type of documentTypes | keyvalue" [value]="type.key">{{ type.value }}</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="label" for="nroDocumento">Nro de Documento</label>
            <input matInput class="form-control" type="text" formControlName="nroDocumento" maxlength="255">
            <small class="text-danger float-left" *ngIf="getCtrl('step2.nroDocumento')?.touched
                && getCtrl('step2.nroDocumento')?.hasError('conditionalRequired')">
              Ingrese un valor para el tipo de documento seleccionado
            </small>
            <small class="char-count float-right">{{ form?.value?.step2?.nroDocumento?.length || 0 }}/255</small>
          </div>
        </div>
      </div>
      <div class="row margin-content">
        <div class="col-12">
          <div class="divisor-line" *ngIf="!documentTypesLoading && (documentTypes | keyvalue)?.length"></div>
          <div class="form-group">
            <div class="label required">Descripción</div>
            <textarea class="form-control scrollbar-dark" rows="3" placeholder="Detalle aquí la razón de su consulta."
              formControlName="descripcion" maxlength="250" required>
            </textarea>
            <small class="text-danger float-left"
              *ngIf="getCtrl('step2.descripcion')?.touched && getCtrl('step2.descripcion')?.hasError('required')">
              Ingrese una descripción
            </small>
            <small class="float-right">{{ form?.value?.step2?.descripcion?.length || 0 }}/250</small>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="documentTypesLoading">
        <div class="col d-flex justify-content-center">
          <mat-progress-spinner mode="indeterminate" diameter="25" class="edit-spinner">
          </mat-progress-spinner>
        </div>
      </div>
      <div class="row margin-content">
        <div class="col">
          <div class="form-group">
            <div class="label">Adjuntar archivo</div>
            <app-select-file formControlName="archivo" [acceptTypes]="validFileFormats"></app-select-file>
            <small *ngIf="getCtrl('step2.archivo')?.touched && invalidSizeError" class="text-danger float-left">
              El archivo supera el tamaño máximo de 8 MB permitido
            </small>
            <small *ngIf="getCtrl('step2.archivo')?.touched && invalidFormatError" class="text-danger float-left">
              El archivo no posee un formato válido
            </small>
            <small class="float-left" *ngIf="!(invalidFormatError || invalidSizeError)">
              Sólo archivos de imagen, texto o pdf, hasta 8MB
            </small>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</form>
<div class="footer">
  <div class="flex-grow-1" *ngIf="stepper.selectedIndex > 0">
    <button class="btn clear-primary" (click)="previous()" @fadeIn>
      <mat-icon>chevron_left</mat-icon>
      Volver
    </button>
  </div>
  <button class="btn mr-2" (click)="closeModal()" @fadeIn>
    Cancelar
  </button>
  <button *ngIf="stepper.selectedIndex === 0" class="btn btn-primary" (click)="next()" [disabled]="form.controls.step1.invalid">
    Siguiente
  </button>
  <button *ngIf="stepper.selectedIndex === stepper.steps.length - 1" type="submit" class="btn btn-primary" (click)="sendTicket()" [disabled]="form.invalid">
    <mat-progress-spinner class="send-spinner" [ngStyle]="{ visibility: loading ? 'visible' : 'hidden' }"
      mode="indeterminate" diameter="20">
    </mat-progress-spinner>
    <div [ngStyle]="{ visibility: !loading ? 'visible' : 'hidden' }">Crear</div>
  </button>
</div>