import { Component } from '@angular/core';

@Component({
  selector: 'agd-card-skeleton',
  templateUrl: './card-skeleton.component.html',
  styleUrls: ['./card-skeleton.component.scss']
})
export class CardSkeletonComponent {
  circleStyle = {
    'background-color': '#eff1ff',
    'height.px': '20',
    'width.px': '20',
    'border-radius': '4px'
  };
}
