import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { SecurePipeModule } from '../../pipes/secure/secure-pipe.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    FilePreviewComponent,
  ],
  imports: [
    CommonModule,
    SecurePipeModule,
    MatIconModule,
  ],
  exports: [
    FilePreviewComponent,
  ]
})
export class FilePreviewModule { }
